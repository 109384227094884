/* font families */
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

/**
 * brand color palette
 * orange: #EE7A30
 * pink: #EA368A
 * purple: #7A2CF5
 * grey 5%: #F6F7F8
 * grey 10%: #EEF0F1
 * grey 25%: #D6D9DD
 * grey 50%: #ADB4B9
 * grey 75%: #838F98
 * grey (100%): #5A6A76
 * dark grey: #30393D
 *
 * additional colors
 * red: #D87B7B
 * high contrast dark grey: #484E59
 */

/* base styles */
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #484E59;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App {
  min-height: 100vh;
}

/* base typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  margin: 0;
}
h1 {
  color: #5A6A76;
}
p {
  margin: 0;
}
input,
textarea,
select,
option {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #30393D;
}
.subtitle {
  font-weight: 700;
}

/* links */
.App a {
  color: #7A2CF5;
}

.App a:hover,
.App a:active,
.App a:focus {
  color: #7A2CF5;
}

/* buttons */
.App button {
  cursor: pointer;
  color: #838F98;
  color: #30393D;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 15px;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right, #EE7A30, #EA368A, #7A2CF5);
  border-image-slice: 1;
  background-color: #fff;
}
.App button:disabled {
  cursor: default;
  color: #838F98;
  background-color: #F6F7F8;
  border-color: #D6D9DD;
  border-image: none;
}
.App button.dark {
  color: white;
  border: none;
  background-image: linear-gradient(to right, #EE7A30, #EA368A, #7A2CF5);
}

/* forms */
.AppFormTitle {
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 25px;
}
.AppInput {
  margin-bottom: 20px;
}
/* form error message */
.AppInput.error {
  color: white;
  background: #EA368A;
  background: #EE7A30;
  background: #EE7A30;
  padding: 10px;
}
.AppInput.error p {
  margin: 0;
}
/* form input error message */
.AppInput.has-error input[type="text"],
.AppInput .has-error input[type="text"],
.AppInput.has-error input[type="date"],
.AppInput .has-error input[type="date"],
.AppInput.has-error input[type="number"],
.AppInput .has-error input[type="number"],
.AppInput.has-error select,
.AppInput .has-error select {
  border-color: #EE7A30;
}
.AppInputError {
  color: #EE7A30;
  font-size: 11px;
}
.AppInput label {
  display: inline-block;
  margin-bottom: 10px;
}
.AppInput input[type="text"],
.AppInput input[type="email"],
.AppInput input[type="date"],
.AppInput input[type="password"],
.AppInput input[type="number"],
.AppInput select,
.AppInput textarea {
  display: block;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border: 1px solid #EEF0F1;
  margin: 0 0 18px;
  padding: 15px;
}
.AppInput textarea {
  border: 1px solid #EEF0F1;
}
label.AppInput-radio-label {
  display: block;
  position: relative;
  user-select: none;
  cursor: pointer;
  padding: 5px 10px 5px 35px;
  margin: 0;
}
.AppInput-radio-checkmark {
  content: " ";
  position: absolute;
  top: 5px;
  left: 0px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #EEF0F1;
  transition: background-color 300ms;
}
.AppInput-radio-label:hover .AppInput-radio-checkmark {
  background-color: #ADB4B9;
}
.AppInput-radio-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.AppInput-radio-label input:checked ~ .AppInput-radio-checkmark {
  background-color: #EE7A30;
  background-color: #30393D;
  background-color: #484E59;
  /*
  background-image: linear-gradient(to right, #EE7A30, #EA368A, #7A2CF5);
  */
}
.AppInput-radio-label input:checked ~ .AppInput-radio-checkmark:after {
  content: " ";
  display: block;
  position: absolute;
  left: 8.5px;
  top: 8.5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
}
.AppSubmit-2-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20%;
  margin: 0;
}
.AppSubmit button {
  width: 100%;
  display: inline-block;
  padding: 15px;
}

