@import url(https://fonts.googleapis.com/css?family=Montserrat:300,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.ContentBlock {
  margin: 35px 20px;
  padding: 20px;
}
.ContentBlock.ver-center {
  text-align: center;
}
.ContentBlock-title {
  font-size: 35px;
  line-height: 40px;
  margin-top: 35px;
  margin-bottom: 45px;
}
.ContentBlock p {
  margin-bottom: 25px;
}

.CTAButtonGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20%;
}

.CTAButton {
  display: block;
  cursor: pointer;
  text-align: center;
  padding: 15px;
}

.ContentWithSidebar {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.ContentWithSidebar.has-background {
  background-color: #EEF0F1;
}

.Footer {
  color: white;
  margin-top: 50px;
  background-color: #30393D;
  padding-bottom: 50px;
}
.Footer-disclaimer {
  font-size: 10px;
}
.Footer p:last-child {
  margin-bottom: 0;
}
.Footer-nav {
  font-size: 12px;
  list-style-type: none;
}
.Footer-nav-item {
  display: inline-block;
  padding-left: 0;
  margin-right: 20px;
}
.App .Footer-nav-item a,
.App .Footer-nav-item a:active,
.App .Footer-nav-item a:focus,
.App .Footer-nav-item a:hover {
  color: white;
  text-decoration: none;
}
.App .Footer-nav-item a:hover {
  text-decoration: underline;
}

.Header {
  background-color: white;
  padding: 35px 40px;
}
.Header a.Header-brand-link,
.Header a.Header-brand-link:hover,
.Header a.Header-brand-link:active,
.Header a.Header-brand-link:visited {
  color: #5A6A76;
}
.Header.ver-center {
  text-align: center;
}
.Header-logo {
  display: inline-block;
  width: 50px;
  height: 67px;
  vertical-align: top;
  margin-right: 20px;
}
.Header.ver-center .Header-logo {
  margin-left: 20px;
}
.Header-title {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  line-height: 67px;
  letter-spacing: 6px;
  text-transform: uppercase;
  margin: 0;
}

.MainMenu-main {
  padding-left: 0;
  list-style-type: none;
  color: #484E59;
  text-decoration: none;
  font-size: 16px;
  font-weight: 100;
}
.MainMenu-main-item {
  display: inline-block;
  position: relative;
  margin-right: 20px;
}
.MainMenu-expand {
  position: relative;
  top: 6px;
}
.App .MainMenu a,
.App .MainMenu a:focus,
.App .MainMenu a:active,
.App .MainMenu a:hover {
  color: #484E59;
  text-decoration: none;
  font-size: 16px;
  font-weight: 100;
}
.App .MainMenu a:hover {
  text-decoration: underline;
}
.MainMenu-sub {
  width: 240px;
  padding-left: 0;
  list-style-type: none;
  position: absolute;
  opacity: 0;
  transition: opacity 300ms;
}
.MainMenu-main-item:hover .MainMenu-sub {
  background: #fff;
  opacity: 1;
}
.MainMenu-sub-item {
  padding: 12px 20px;
}


.GridCenter {
  min-width: 100%;
  min-height: 100%;
  display: grid;
  grid-template-areas:
    ". . ."
    ". a ."
    ". . .";
}
.GridCenter.ver-full {
  min-height: 100vh;
}
.GridCenter-item {
  position: relative;
  grid-area: a;
  align-self: center;
  justify-self: center;
}

.ArticlePreview {
  margin: 35px 20px;
  padding: 20px;
  background-color: white;
}
.ArticlePreview-image img {
  max-width: 100%;
}
.ArticlePreview .ArticlePreview-category {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 12px;
}
.ArticlePreview-title {
  margin-top: 15px;
  margin-bottom: 20px;
}
.ArticlePreview-title a,
.ArticlePreview-title a:active,
.ArticlePreview-title a:focus,
.ArticlePreview-title a:hover {
  text-decoration: none;
}
.ArticlePreview-title a:hover {
  text-decoration: underline;
}
.ArticlePreview p {
  margin-bottom: 20px;
}


.Grid2Columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
}

.ItemList {
  margin: 15px 20px;
}
.ItemList.is-open {
  background-color: #F6F7F8;
}
.ItemList-header {
  cursor: pointer;
  display: grid;
  padding: 15px 20px;
  grid-template-columns: 8fr 1fr;
  grid-column-gap: 5%;
  transition: background-color 475ms ease;
}
.ItemList.is-open .ItemList-header {
  background-color: #EEF0F1;
}
.ItemList-header:hover {
  background-color: #EEF0F1;
}
.ItemList.is-open .ItemList-header:hover {
  background-color: #D6D9DD;
}
.ItemList-title {
  font-size: 17px;
}
.ItemList-title.is-type-all-items {
  font-size: 25px;
}
.ItemList-header-open-indicator {
  margin-right: 20px;
}
.ItemList-total {
  font-size: 15px;
  text-align: right;
}
.ItemList-total.is-type-all-items {
  font-size: 20px;
}
.ItemList-description {
  padding: 10px 20px;
}
.ItemList-bulk-actions {
  padding: 10px 20px;
}
.ItemList-bulk-actions .AppInput {
  margin-bottom: 0;
}
.ItemList-bulk-actions .AppInput select {
  margin-bottom: 0;
}
.ItemList-items-header,
.ItemList-item {
  display: grid;
  padding: 10px 20px;
  grid-template-columns: 0.1fr 6fr 2fr 1fr 2fr;
  grid-column-gap: 5%;
  transition: background-color 475ms ease;
}
.ItemList-items-header {
  font-weight: 700;
}
.ItemList-item.is-editable:hover {
  cursor: pointer;
}
.ItemList-item:hover {
  background-color: #D6D9DD;
}
.ItemList-item.is-selected {
  background-color: #EEF0F1;
  background-color: #EBCEFF;
}
.ItemList-item.is-selected:hover {
  background-color: #EEF0F1;
  background-color: #EBCEFF;
  background-color: #D9AFF6;
}
.ItemList-items-header {
  grid-template-areas: "select title date amount actions";
  border-bottom: 1px solid #EEF0F1;
  border-bottom: 0px solid #EEF0F1;
}
.ItemList-main {
  padding-bottom: 20px;
}
.ItemList-items-header-date {
  grid-area: date;
}
.ItemList-items-header-amount {
  text-align: right;
  grid-area: amount;
}
.ItemList-items-header-actions {
  grid-area: actions;
}
.App a.ItemList-item-select {
  color: #484E59;
}
.App a.ItemList-item-select:hover {
  color: #000;
}
.App a.ItemList-item-action {
  color: #5A6A76;
  color: #ADB4B9;
  color: #484E59;
  margin: 0 10px 0;
  /*opacity: 0.25;
  transition: opacity 475ms ease, color 475ms ease;
  */
}
.App a.ItemList-item-action:hover,
.App a.ItemList-item-action:active {
  color: #EE7A30;
  color: #484E59;
}
.App a.ItemList-item-comments-link {
  color: #5A6A76;
  color: #ADB4B9;
  color: #484E59;
  margin: 0 0 0 20px;
  opacity: 0.25;
  transition: opacity 475ms ease, color 475ms ease;
}
a.ItemList-item-comments-link:hover,
a.ItemList-item-comments-link:active {
  color: #EE7A30;
  color: #484E59;
}
.ItemList-item:hover a.ItemList-item-action,
.ItemList-item:hover a.ItemList-item-comments-link {
  opacity: 1;
}
.ItemList-item-amount {
  text-align: right;
}
.ItemList-item.is-negative .ItemList-item-amount {
  color: #D87B7B;
}

.ModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /*
  background-color: rgba(255,255,255,0.95);
  background-color: rgba(0,0,0,0.7);
  background: linear-gradient(to bottom right, #EE7A30, #EA368A, #7A2CF5);
  background: linear-gradient(to bottom right, rgba(137,37,244,0.35), rgba(252,119,41,0.35));
  background: linear-gradient(to bottom right, rgba(137,37,244,0.75), rgba(252,119,41,0.75));
  background-color: rgba(0,0,0,0.75);
  */
  background: linear-gradient(to right, rgba(0,0,0,0.85), rgba(0,0,0,0.75), rgba(0,0,0,0.85));
}
.App button.ModalWrapper-close {
  position: absolute;
  display: inline-block;
  font-size: 20px;
  top: 50px;
  right: 25px;
  padding: 10px 15px;
  background-color: white;
}
.ModalWrapper-inner {
  width: 80vw;
  max-height: 80vh;
  padding: 25px;
  margin: 25px auto 25px;
  background-color: white;
  box-shadow: 0px 0px 25px #999;
  box-shadow: 0px 0px 25px #000;
  overflow: scroll;
  -webkit-animation: fade-in-slide-up 450ms ease-out;
          animation: fade-in-slide-up 450ms ease-out;
}
.ModalWrapper-header {
  margin: 0 0 30px;
}
.ModalWrapper-header button {
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 15px;
}
@-webkit-keyframes fade-in-slide-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes fade-in-slide-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.PaymentGroupForm {
  display: block;
  padding: 0;
  margin-bottom: 25px;
}
.PaymentGroupForm-label-note {
  font-size: 10px;
}

.ConfirmAction-content {
  min-height: 60px;
  padding: 25px 0 25px;
}

/* font families */

/**
 * brand color palette
 * orange: #EE7A30
 * pink: #EA368A
 * purple: #7A2CF5
 * grey 5%: #F6F7F8
 * grey 10%: #EEF0F1
 * grey 25%: #D6D9DD
 * grey 50%: #ADB4B9
 * grey 75%: #838F98
 * grey (100%): #5A6A76
 * dark grey: #30393D
 *
 * additional colors
 * red: #D87B7B
 * high contrast dark grey: #484E59
 */

/* base styles */
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #484E59;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App {
  min-height: 100vh;
}

/* base typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  margin: 0;
}
h1 {
  color: #5A6A76;
}
p {
  margin: 0;
}
input,
textarea,
select,
option {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #30393D;
}
.subtitle {
  font-weight: 700;
}

/* links */
.App a {
  color: #7A2CF5;
}

.App a:hover,
.App a:active,
.App a:focus {
  color: #7A2CF5;
}

/* buttons */
.App button {
  cursor: pointer;
  color: #838F98;
  color: #30393D;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 15px;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right, #EE7A30, #EA368A, #7A2CF5);
  border-image-slice: 1;
  background-color: #fff;
}
.App button:disabled {
  cursor: default;
  color: #838F98;
  background-color: #F6F7F8;
  border-color: #D6D9DD;
  border-image: none;
}
.App button.dark {
  color: white;
  border: none;
  background-image: linear-gradient(to right, #EE7A30, #EA368A, #7A2CF5);
}

/* forms */
.AppFormTitle {
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 25px;
}
.AppInput {
  margin-bottom: 20px;
}
/* form error message */
.AppInput.error {
  color: white;
  background: #EA368A;
  background: #EE7A30;
  background: #EE7A30;
  padding: 10px;
}
.AppInput.error p {
  margin: 0;
}
/* form input error message */
.AppInput.has-error input[type="text"],
.AppInput .has-error input[type="text"],
.AppInput.has-error input[type="date"],
.AppInput .has-error input[type="date"],
.AppInput.has-error input[type="number"],
.AppInput .has-error input[type="number"],
.AppInput.has-error select,
.AppInput .has-error select {
  border-color: #EE7A30;
}
.AppInputError {
  color: #EE7A30;
  font-size: 11px;
}
.AppInput label {
  display: inline-block;
  margin-bottom: 10px;
}
.AppInput input[type="text"],
.AppInput input[type="email"],
.AppInput input[type="date"],
.AppInput input[type="password"],
.AppInput input[type="number"],
.AppInput select,
.AppInput textarea {
  display: block;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border: 1px solid #EEF0F1;
  margin: 0 0 18px;
  padding: 15px;
}
.AppInput textarea {
  border: 1px solid #EEF0F1;
}
label.AppInput-radio-label {
  display: block;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  padding: 5px 10px 5px 35px;
  margin: 0;
}
.AppInput-radio-checkmark {
  content: " ";
  position: absolute;
  top: 5px;
  left: 0px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #EEF0F1;
  transition: background-color 300ms;
}
.AppInput-radio-label:hover .AppInput-radio-checkmark {
  background-color: #ADB4B9;
}
.AppInput-radio-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.AppInput-radio-label input:checked ~ .AppInput-radio-checkmark {
  background-color: #EE7A30;
  background-color: #30393D;
  background-color: #484E59;
  /*
  background-image: linear-gradient(to right, #EE7A30, #EA368A, #7A2CF5);
  */
}
.AppInput-radio-label input:checked ~ .AppInput-radio-checkmark:after {
  content: " ";
  display: block;
  position: absolute;
  left: 8.5px;
  top: 8.5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
}
.AppSubmit-2-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20%;
  margin: 0;
}
.AppSubmit button {
  width: 100%;
  display: inline-block;
  padding: 15px;
}


