.Header {
  background-color: white;
  padding: 35px 40px;
}
.Header a.Header-brand-link,
.Header a.Header-brand-link:hover,
.Header a.Header-brand-link:active,
.Header a.Header-brand-link:visited {
  color: #5A6A76;
}
.Header.ver-center {
  text-align: center;
}
.Header-logo {
  display: inline-block;
  width: 50px;
  height: 67px;
  vertical-align: top;
  margin-right: 20px;
}
.Header.ver-center .Header-logo {
  margin-left: 20px;
}
.Header-title {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  line-height: 67px;
  letter-spacing: 6px;
  text-transform: uppercase;
  margin: 0;
}
