.ContentBlock {
  margin: 35px 20px;
  padding: 20px;
}
.ContentBlock.ver-center {
  text-align: center;
}
.ContentBlock-title {
  font-size: 35px;
  line-height: 40px;
  margin-top: 35px;
  margin-bottom: 45px;
}
.ContentBlock p {
  margin-bottom: 25px;
}
