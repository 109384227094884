.ModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /*
  background-color: rgba(255,255,255,0.95);
  background-color: rgba(0,0,0,0.7);
  background: linear-gradient(to bottom right, #EE7A30, #EA368A, #7A2CF5);
  background: linear-gradient(to bottom right, rgba(137,37,244,0.35), rgba(252,119,41,0.35));
  background: linear-gradient(to bottom right, rgba(137,37,244,0.75), rgba(252,119,41,0.75));
  background-color: rgba(0,0,0,0.75);
  */
  background: linear-gradient(to right, rgba(0,0,0,0.85), rgba(0,0,0,0.75), rgba(0,0,0,0.85));
}
.App button.ModalWrapper-close {
  position: absolute;
  display: inline-block;
  font-size: 20px;
  top: 50px;
  right: 25px;
  padding: 10px 15px;
  background-color: white;
}
.ModalWrapper-inner {
  width: 80vw;
  max-height: 80vh;
  padding: 25px;
  margin: 25px auto 25px;
  background-color: white;
  box-shadow: 0px 0px 25px #999;
  box-shadow: 0px 0px 25px #000;
  overflow: scroll;
  animation: fade-in-slide-up 450ms ease-out;
}
.ModalWrapper-header {
  margin: 0 0 30px;
}
.ModalWrapper-header button {
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 15px;
}
@keyframes fade-in-slide-up {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
