.MainMenu-main {
  padding-left: 0;
  list-style-type: none;
  color: #484E59;
  text-decoration: none;
  font-size: 16px;
  font-weight: 100;
}
.MainMenu-main-item {
  display: inline-block;
  position: relative;
  margin-right: 20px;
}
.MainMenu-expand {
  position: relative;
  top: 6px;
}
.App .MainMenu a,
.App .MainMenu a:focus,
.App .MainMenu a:active,
.App .MainMenu a:hover {
  color: #484E59;
  text-decoration: none;
  font-size: 16px;
  font-weight: 100;
}
.App .MainMenu a:hover {
  text-decoration: underline;
}
.MainMenu-sub {
  width: 240px;
  padding-left: 0;
  list-style-type: none;
  position: absolute;
  opacity: 0;
  transition: opacity 300ms;
}
.MainMenu-main-item:hover .MainMenu-sub {
  background: #fff;
  opacity: 1;
}
.MainMenu-sub-item {
  padding: 12px 20px;
}

